<template>
  <b-card
    :class="innerWidth ? classes : 'ml-0'"
  >
    <balance-credit
      :payment-tokens="paymentTokens"
      view="customer"
      @reload:payment-tokens="loadClientPaymentTokens($route.params.id, 0)"
      @on:interface-switch="onSwitch"
    />
    <b-row align="center" class="mt-3" v-if="paymentTokens.length === 0">
      <b-col>
        {{ $t('views.client.details.body.right.balance.no-credit') }}
      </b-col>
    </b-row>
    <b-row v-if="!switched" no-gutters align="center" class="mt-3">
      <b-col cols="12">
        <b-col
          cols="12"
          :class="innerWidth ? '' : 'p-0'"
        >
          <d-button
            :class="innerWidth ? '' : ' custom-customer-button mt-4 mb-3'"
            text="views.client.details.body.right.balance.add-credit"
            class="btn-text-to-display d-btn-sm btn d-btn-load-document font-text-title"
            icon="customer-btn-icon fa fa-product-hunt mr-1"
            icon-size="15"
            @on:button-click="$router.push({name: 'customer_credits-pack_new', params: { id: $route.params.id }})"
          />
        </b-col>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {getAllClientPaymentToken} from "@api/doinsport/services/client/payment-token/payment-token.api";
import {getClientWallet} from "@api/doinsport/services/client/wallet/wallet.api";
import PaymentToken from "@/classes/doinsport/PaymentToken";
import Wallet from "@/classes/doinsport/Wallet";

export default {
  props: {
    classes: {
      type: String,
      default: 'ml-3'
    }
  },
  data: () => ({
    paymentTokens: [],
    wallet: null,
    switched: false,
  }),
  components: {
    BalanceCredit: () => import('./BalanceCredit'),
    BalanceWallet: () => import('./BalanceWallet'),
  },
  computed:{
    innerWidth(){
      return this.$store.getters['layout/getInnerWidth'] > 900;
    },
  },
  methods: {
    onSwitch(scope) {
      this.switched = scope;
    },
    loadClientPaymentTokens(clientId, balance) {
      this.paymentTokens = [];

      getAllClientPaymentToken(clientId, balance)
        .then((response) => {
          for (const paymentToken of response.data['hydra:member']) {
            this.paymentTokens.push(new PaymentToken(paymentToken, {serialize: true}));
          }
        })
      ;
    },
  },
  created() {
    this.loadClientPaymentTokens(this.$route.params.id, 0);
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
@import "@lazy/_custom-buttom-mobile.scss";

.balance-title {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

</style>
