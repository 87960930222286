import {_get, _post, _put} from "@api/doinsport/services/httpService";
import {URL_CLIENT} from "../index";

export const URI = URL_CLIENT + "/payment-tokens"

export const getPaymentToken = (id, fromDate, balance) =>
  _get(URI +
    '?expiresAt[after]=' + fromDate +
    '&client.id=' + id +
    '&balance[gt]=' + balance
  )
;

export const getPaymentTokenModel = (id) => _get(URI + '/' + id);

export const postCustomerPaymentToken = (data) => _post(URI, data);

export const putPaymentToken = (paymentToken) => _put(URI + '/' + paymentToken.id, paymentToken);

export const getAllClientPaymentToken = (id, balance, extraParams = '') =>
  _get(URI +
    '?client.id=' + id +
    '&balance[gt]=' + balance +
    extraParams
  )
;
